/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import { AppearOnScroll, Grid, PortableText } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

/** ============================================================================
 * @component
 * Case study text block.
 */
const CaseStudyText = ({ data: { subheading, heading, content } }) => {
  if (!subheading && !heading && !text) {
    return <></>;
  }

  return (
    <AppearOnScroll>
      <section
        css={css`
          width: 100%;
          position: relative;
          padding: 4rem 0 2rem;

          ${MEDIA_QUERIES?.desktop} {
            padding: 5rem 0 6rem;
          }
        `}
      >
        <Grid node="article">
          <header
            css={css`
              grid-column: span 12 / span 12;

              ${MEDIA_QUERIES?.desktop} {
                grid-column: span 8 / span 8;
                grid-column-start: 3;
              }

              ${MEDIA_QUERIES?.large} {
                grid-column: span 6 / span 6;
                grid-column-start: 4;
              }
            `}
          >
            <h2
              css={css`
                color: var(--color-light-aqua);
                text-align: center;
              `}
              className="h4"
            >
              {subheading}
            </h2>

            <h3
              css={css`
                margin: 2rem 0 2.5rem;
                text-align: center;
              `}
              className="h2"
            >
              {heading}
            </h3>
          </header>

          {content?.[0] &&
            content.map((contentItem, contentItemIndex) => (
              <div
                css={css`
                  grid-column: span 12 / span 12;
                  position: relative;
                  margin-bottom: 1rem;
                  ${MEDIA_QUERIES?.desktop} {
                    text-align: left;
                    grid-column: ${contentItemIndex === content.length - 1 &&
                    content.length % 2 !== 0
                      ? ` span 10 / span 10`
                      : ` span 5 / span 5`};
                    grid-column-start: ${contentItemIndex % 2 === 0
                      ? `2`
                      : `7`};
                    padding-right: 2rem;
                  }
                `}
              >
                <PortableText blocks={contentItem?._rawBlockContent} />
              </div>
            ))}
        </Grid>
      </section>
    </AppearOnScroll>
  );
};

/** ============================================================================
 * graphql query
 */
export const query = graphql`
  fragment CaseStudyTextFragment on SanityCaseStudyText {
    _key
    _type

    subheading
    heading
    content {
      _rawBlockContent
    }
  }
`;

export default CaseStudyText;
