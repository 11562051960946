/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import React from "react";
import { graphql } from "gatsby";
import { css } from "@emotion/react";
import { AppearOnScroll, Grid, Image } from "~components";
import { MEDIA_QUERIES } from "~utils/css";

/** ============================================================================
 * @component
 * Repeatable image grid component for case study pages.
 */
const CaseStudyMedia = ({ data: { media } }) => {
  if (!media?.[0]) {
    return <></>;
  }

  return (
    <AppearOnScroll>
      <section
        css={css`
          width: 100%;
          position: relative;
          padding: 2rem 0 2rem;
          ${MEDIA_QUERIES?.desktop} {
            padding: 2rem 0 6rem;
          }
        `}
      >
        <Grid node="article">
          {media.map((mediaItem, mediaItemIndex) => (
            <figure
              css={css`
                grid-column: span 12 / span 12;
                margin-bottom: 1rem;

                ${MEDIA_QUERIES?.desktop} {
                  grid-column: span 5 / span 5;
                  grid-column-start: ${mediaItemIndex % 2 === 0 ? `2` : `7`};
                }
              `}
            >
              <Image image={mediaItem} />
            </figure>
          ))}
        </Grid>
      </section>
    </AppearOnScroll>
  );
};

/** ============================================================================
 * graphql query
 */
export const query = graphql`
  fragment CaseStudyMediaFragment on SanityCaseStudyMedia {
    _key
    _type

    media {
      altText

      asset {
        originalFilename
        title
        description

        gatsbyImageData(width: 1440, formats: [AUTO, WEBP, AVIF])
      }
    }
  }
`;

export default CaseStudyMedia;
